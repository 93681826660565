/* eslint-disable */
import Types from "../actions/types";
import { createReducer } from "reduxsauce";
import COMMON_STATE from "./initial-state/commonState";
import Moment from "moment-timezone";

let imageArray = [];

const fillImageArray = (obj) => {
  if (imageArray && !containsObject(obj, imageArray)) {
    imageArray.push(obj);
  }
  return imageArray;
};

const containsObject = (obj, array) => {
  if (
    array.some(function (o) {
      return (
        o["imageId"] === obj.imageId &&
        (o["imageSmall"] === obj["imageSmall"] &&
          o["imageMedium"] === obj["imageMedium"] &&
          o["imageLarge"] === obj["imageLarge"])
      );
    })
  ) {
    return true;
  }
  return false;
};

const genericApiFailure = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: action.data,
  });

const genericApiSuccess = (state, action) => Object.assign({}, state, {});

const resetFetchingFlag = (state, action) =>
  Object.assign({}, state, {
    fetching: undefined,
  });

const setAccessToken = (state, action) =>
  Object.assign({}, state, {
    accessToken: action.accessToken,
  });

const resetTableLoading = (state, action) =>
  Object.assign({}, state, {
    TableDataLoading: true,
  });

const setUserHash = (state, action) =>
  Object.assign({}, state, {
    UserHash: action.userHash,
    UserHashReceived: true,
  });

const resetSummaryModalStates = (state, action) => 
  Object.assign({}, state, {
    QuoteLoading: true,
    EquitySummaryLoading: true,
    Quote: {},
    EquitySummary: {}
  })

const resetCollectionDetailsLoading = (state, action) =>
  Object.assign({}, state, {
    CollectionDetailsLoading: true,
    TableDataLoading: true,
    CollectionDetails: {},
    AllCompaniesTableData: [],
    PreferencesLoading: true,
    ToolTipDefinitionsLoading:true
  });

const responseGetAllCollections = (state, action) =>
  Object.assign({}, state, {
    AllCollections: action.response,
    CollectionsLoading: false,
  });

const responseGetAllCollectionsFail = (state, action) =>
  Object.assign({}, state, {
    Error: action.response.data,
    CollectionsLoading: true,
  });

const responseGetAllCompaniesTableData = (state, action) =>
  Object.assign({}, state, {
    AllCompaniesTableData: action.response,
    TableDataLoading: false,
    TimeStamp: Moment(new Date().getTime())
      .tz("Australia/Sydney")
      .format("hh:mm:ss A z, DD MMM YYYY"),
  });

const responseGetAllCompaniesTableDataFail = (state, action) =>
  Object.assign({}, state, {
    Error: action.response.data,
    TableDataLoading: true,
  });

const responseGetCollectionDetails = (state, action) =>
  Object.assign({}, state, {
    CollectionDetails: action.response,
    CollectionDetailsLoading: false,
    ImageDataLoading: true,
  });

const responseGetCollectionDetailsFail = (state, action) =>
  Object.assign({}, state, {
    Error: action.response.data,
    CollectionDetailsLoading: true,
  });

const responseGetFavourites = (state, action) =>
  Object.assign({}, state, {
    FavouriteCollections: action.response,
    FavouriteCollectionsLoading: false,
  });

const responseGetFavouritesFail = (state, action) =>
  Object.assign({}, state, {
    Error: action.response.data,
    FavouriteCollectionsLoading: true,
    UserHashReceived: true,
    UserHash: ''
  });

const responseGetImage = (state, action) =>
  Object.assign({}, state, {
    ImageData: fillImageArray(action.response.data),
    ImageDataLoading: false,
  });

const responseGetImageFail = (state, action) =>
  Object.assign({}, state, {
    Error: action.response.data,
    ImageDataLoading: true,
  });

const responseGetEquitySummary = (state, action) =>
  Object.assign({}, state, {
    EquitySummary: action.response.data,
    EquitySummaryLoading: false,
    TimeStamp: Moment(new Date().getTime())
      .tz("Australia/Sydney")
      .format("hh:mm:ss A z, DD MMM YYYY"),
  });

const responseGetEquitySummaryFail = (state, action) =>
  Object.assign({}, state, {
    Error: action.response.data,
    EquitySummaryLoading: true,
  });

const responseGetQuote = (state, action) =>
  Object.assign({}, state, {
    Quote: action.response.data,
    QuoteLoading: false,
    TimeStamp: Moment(new Date().getTime())
      .tz("Australia/Sydney")
      .format("hh:mm:ss A z, DD MMM YYYY"),
  });

const responseGetQuoteFail = (state, action) =>
  Object.assign({}, state, {
    Error: action.response.data,
    QuoteLoading: true,
  });

const responseSetPreferencesFail = (state, action) =>
  Object.assign({}, state, {
    PreferencesLoading : false,
  });

const responseGetPreferences = (state, action) => 
  Object.assign({},state,{
    Preferences : action.response.data,
    PreferencesLoading : false
  })

const responseGetPreferencesFail = (state, action) => 
  Object.assign({},state,{
    Error : action.response.data,
    PreferencesLoading : false
  })
const responseGetTooltipDefinitions = (state, action) =>
  Object.assign({}, state, {
    ToolTipDefinitions: action.response.data,
    ToolTipDefinitionsLoading: false,
  });

const responseGetTooltipDefinitionsFail = (state, action) =>
  Object.assign({}, state, {
    Error: action.response.data,
    ToolTipDefinitionsLoading: true,
  });

const responseGetCollectionHighlights = (state, action) =>
  Object.assign({}, state, {
    CollectionHighlights: action.response,
    HighlightsLoading: false,
  });

const responseGetCollectionHighlightsFail = (state, action) =>
  Object.assign({}, state, {
    Error: action.response.data,
    HighlightsLoading: true,
  });

const ACTION_HANDLERS = {
  [Types.SET_ACCESS_TOKEN]: setAccessToken,
  [Types.SET_USER_HASH]: setUserHash,
  [Types.GENERIC_FAILURE]: genericApiFailure,
  [Types.GENERIC_SUCCESS]: genericApiSuccess,
  [Types.RESET_FETCHING_FLAG]: resetFetchingFlag,
  [Types.RESET_TABLE_LOADING]: resetTableLoading,
  [Types.RESET_SUMMARY_MODAL_STATES]: resetSummaryModalStates,
  [Types.RESET_COLLECTION_DETAILS_LOADING]: resetCollectionDetailsLoading,
  [Types.GET_ALL_COLLECTIONS]: responseGetAllCollections,
  [Types.GET_ALL_COLLECTIONS_FAIL]: responseGetAllCollectionsFail,
  [Types.GET_ALL_COMPANIES_TABLE_DATA]: responseGetAllCompaniesTableData,
  [Types.GET_ALL_COMPANIES_TABLE_DATA_FAIL]: responseGetAllCompaniesTableDataFail,
  [Types.GET_COLLECTION_DETAILS]: responseGetCollectionDetails,
  [Types.GET_COLLECTION_DETAILS_FAIL]: responseGetCollectionDetailsFail,
  [Types.GET_FAVOURITES]: responseGetFavourites,
  [Types.GET_FAVOURITES_FAIL]: responseGetFavouritesFail,
  [Types.SET_PREFERENCES_FAIL]: responseSetPreferencesFail,
  [Types.GET_IMAGE]: responseGetImage,
  [Types.GET_IMAGE_FAIL]: responseGetImageFail,
  [Types.GET_EQUITY_SUMMARY]: responseGetEquitySummary,
  [Types.GET_EQUITY_SUMMARY_FAIL]: responseGetEquitySummaryFail,
  [Types.GET_QUOTE]: responseGetQuote,
  [Types.GET_QUOTE_FAIL]: responseGetQuoteFail,
  [Types.GET_PREFERENCES]: responseGetPreferences,
  [Types.GET_PREFERENCES_FAIL]: responseGetPreferencesFail,
  [Types.GET_COLLECTION_HIGHLIGHTS_FAIL]: responseGetCollectionHighlightsFail,
  [Types.GET_COLLECTION_HIGHLIGHTS]: responseGetCollectionHighlights,
  [Types.GET_PREFERENCES_FAIL]: responseGetPreferencesFail,
  [Types.GET_TOOLTIP_DEFINITIONS]: responseGetTooltipDefinitions,
  [Types.GET_TOOLTIP_DEFINITIONS_FAIL]: responseGetTooltipDefinitionsFail
};

export default createReducer(COMMON_STATE, ACTION_HANDLERS);
