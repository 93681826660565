/* eslint-disable no-unused-vars */
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ParallaxProvider } from 'react-scroll-parallax'

const checkIE11 = () => {
    return false || !!document.documentMode
}
!checkIE11() &&
  ReactDOM.render(
    <ParallaxProvider>
      <App />
    </ParallaxProvider>,
    document.getElementById('root')
  )
