/* eslint-disable */
import Types from "./types";

const onGenericApiFailure = (response) => ({
  type: Types.GENERIC_FAILURE,
  data: response.error,
});

const onGenericApiSuccess = (response) => ({
  type:
    response.data.success === true
      ? Types.GENERIC_SUCCESS
      : Types.GENERIC_FAILURE,
  data: response.error,
});

const resetFetchingFlag = () => ({
  type: Types.RESET_FETCHING_FLAG,
});

const resetTableLoading = () => ({
  type: Types.RESET_TABLE_LOADING,
});

const resetSummaryModalStates = () => ({
  type: Types.RESET_SUMMARY_MODAL_STATES
})

const resetCollectionDetailsLoading = () => ({
  type: Types.RESET_COLLECTION_DETAILS_LOADING,
});

const setUserHash = (userHash) => ({
  type: Types.SET_USER_HASH,
  userHash: userHash
});

const setAccessToken = (accessToken) => ({
  type: Types.SET_ACCESS_TOKEN,
  accessToken: accessToken,
});

const responseGetAllCollections = (response) => ({
  type: Types.GET_ALL_COLLECTIONS,
  response,
});

const responseGetAllCollectionsFail = (response) => ({
  type: Types.GET_ALL_COLLECTIONS_FAIL,
  response,
});

const responseGetAllCompaniesTableData = (response) => ({
  type: Types.GET_ALL_COMPANIES_TABLE_DATA,
  response,
});

const responseGetAllCompaniesTableDataFail = (response) => ({
  type: Types.GET_ALL_COMPANIES_TABLE_DATA_FAIL,
  response,
});

const responseGetCollectionDetails = (response) => ({
  type: Types.GET_COLLECTION_DETAILS,
  response,
});

const responseGetCollectionDetailsFail = (response) => ({
  type: Types.GET_COLLECTION_DETAILS_FAIL,
  response,
});

const responseSetTableCollapsed = (response) => ({
  type: Types.SET_TABLE_COLLAPSED,
  response,
});

const responseSetTableCollapsedFail = (response) => ({
  type: Types.SET_TABLE_COLLAPSED_FAIL,
  response,
});

const responseSetTableExpanded = (response) => ({
  type: Types.SET_TABLE_EXPANDED,
  response,
});

const responseSetTableExpandedFail = (response) => ({
  type: Types.SET_TABLE_EXPANDED_FAIL,
  response,
});

const responseSetPreferences = (response) => ({
  type: Types.SET_PREFERENCES,
  response,
});

const responseSetPreferencesFail = (response) => ({
  type: Types.SET_PREFERENCES_FAIL,
  response,
});

const responseGetPreferences = (response) => ({
  type: Types.GET_PREFERENCES,
  response,
});

const responseGetPreferencesFail = (response) => ({
  type: Types.GET_PREFERENCES_FAIL,
  response,
});

const responseGetFavourites = (response) => ({
  type: Types.GET_FAVOURITES,
  response,
});

const responseGetFavouritesFail = (response) => ({
  type: Types.GET_FAVOURITES_FAIL,
  response,
});

const responseGetImage = (response) => ({
  type: Types.GET_IMAGE,
  response,
});

const responseGetImageFail = (response) => ({
  type: Types.GET_IMAGE_FAIL,
  response,
});

const responseGetEquitySummary = (response) => ({
  type: Types.GET_EQUITY_SUMMARY,
  response,
});

const responseGetEquitySummaryFail = (response) => ({
  type: Types.GET_EQUITY_SUMMARY_FAIL,
  response,
});

const responseGetQuote = (response) => ({
  type: Types.GET_QUOTE,
  response,
});

const responseGetQuoteFail = (response) => ({
  type: Types.GET_QUOTE_FAIL,
  response,
});

const responseSetGeneratingCollectionCollapsed = (response) => ({
  type: Types.SET_GENERATING_COLLECTION_COLLAPSED,
  response,
});

const responseSetGeneratingCollectionCollapsedFail = (response) => ({
  type: Types.SET_GENERATING_COLLECTION_COLLAPSED_FAIL,
  response,
});

const responseSetGeneratingCollectionExpanded = (response) => ({
  type: Types.SET_GENERATING_COLLECTION_EXPANDED,
  response,
});

const responseSetGeneratingCollectionExpandedFail = (response) => ({
  type: Types.SET_GENERATING_COLLECTION_EXPANDED_FAIL,
  response,
});

const responseGetTooltipDefinitions = (response) => ({
  type: Types.GET_TOOLTIP_DEFINITIONS,
  response,
});

const responseGetTooltipDefinitionsFail = (response) => ({
  type: Types.GET_TOOLTIP_DEFINITIONS_FAIL,
  response,
});

const responseGetCollectionHighlights = (response) => ({
  type: Types.GET_COLLECTION_HIGHLIGHTS,
  response,
});

const responseGetCollectionHighlightsFail = (response) => ({
  type: Types.GET_COLLECTION_HIGHLIGHTS_FAIL,
  response,
});

const requestGetAllCollections = () => ({
  type: Types.REQUEST_GET_ALL_COLLECTIONS_API,
  endPoint: "commsec-node-api/1.0/collections?v=" + _apiHash_,
  ajaxType: "GET",
  onSuccess: responseGetAllCollections,
  onFailure: responseGetAllCollectionsFail,
});

const requestGetAllCompaniesTableData = (isETF, params) => ({
  type: Types.REQUEST_GET_ALL_COMPANIES_TABLE_DATA_API,
  endPoint: `commsec-node-api/1.0/screener/${isETF ? "etf" : "stock"}`,
  params: params,
  ajaxType: "POST",
  onSuccess: responseGetAllCompaniesTableData,
  onFailure: responseGetAllCompaniesTableDataFail,
});

const requestGetCollectionDetails = (collectionId) => ({
  type: Types.REQUEST_GET_COLLECTION_DETAILS_API,
  endPoint: "commsec-node-api/1.0/collections/" + collectionId + "/detail?v=" + _apiHash_,
  ajaxType: "GET",
  onSuccess: responseGetCollectionDetails,
  onFailure: responseGetCollectionDetailsFail,
});

const requestSetTableCollapsed = (params) => ({
  type: Types.REQUEST_SET_TABLE_COLLAPSED_API,
  endPoint: "commsec-node-api/1.0/collections/tableViewCollapsed",
  params: params,
  ajaxType: "PUT",
  onSuccess: responseSetTableCollapsed,
  onFailure: responseSetTableCollapsedFail,
});

const requestSetPreferences = (collectionId, activeViewId, sorting) => ({
  type: Types.REQUEST_SET_PREFERENCES_API,
  endPoint:
    "commsec-node-api/1.0/collections/" +
    collectionId +
    "/prefrences?activeViewId=" +
    activeViewId +
    sorting,
  ajaxType: "PUT",
  onSuccess: responseSetPreferences,
  onFailure: responseSetPreferencesFail,
});

const requestGetPreferences = (collectionId) => ({
  type: Types.REQUEST_GET_PREFERENCES_API,
  endPoint:
    "commsec-node-api/1.0/collections/" +
    collectionId +
    "/prefrences",
  ajaxType: "GET",
  onSuccess: responseGetPreferences,
  onFailure: responseGetPreferencesFail,
});

const requestSetTableExpanded = (params) => ({
  type: Types.REQUEST_SET_TABLE_EXPANDED_API,
  endPoint: "commsec-node-api/1.0/collections/tableViewExpanded",
  params: params,
  ajaxType: "PUT",
  onSuccess: responseSetTableExpanded,
  onFailure: responseSetTableExpandedFail,
});

const requestGetFavourites = () => ({
  type: Types.REQUEST_GET_FAVOURITES_API,
  endPoint: "commsec-node-api/1.0/collections/favorites",
  ajaxType: "GET",
  onSuccess: responseGetFavourites,
  onFailure: responseGetFavouritesFail,
});

const requestGetImage = (imgId, sizeSpec) => ({
  type: Types.REQUEST_GET_IMAGE_API,
  endPoint:
    "commsec-node-api/1.0/collections/images/" +
    imgId +
    (sizeSpec ? "/" + sizeSpec : "/all") +
    "?v=" + _apiHash_,
  ajaxType: "GET",
  onSuccess: responseGetImage,
  onFailure: responseGetImageFail,
});

const requestGetEquitySummary = (companyCode, isETFCollection) => ({
  type: Types.REQUEST_GET_EQUITY_SUMMARY_API,
  endPoint: `commsec-node-api/1.0/international/${isETFCollection ? "about/companyDetails" : "equity-summary"
    }?symbol=${companyCode}&exchange=ASX`,
  ajaxType: "GET",
  onSuccess: responseGetEquitySummary,
  onFailure: responseGetEquitySummaryFail,
});

const requestGetQuote = (companyCode) => ({
  type: Types.REQUEST_GET_QUOTE_API,
  endPoint: "commsec-node-api/1.0/quote?symbol=" + companyCode,
  ajaxType: "GET",
  onSuccess: responseGetQuote,
  onFailure: responseGetQuoteFail,
});

const requestGetToolTipDefinitions = (appName, pageType, sectionName, glossary) => ({
  type: Types.REQUEST_GET_TOOLTIP_DEFINITIONS_API,
  endPoint:
    "commsec-node-api/1.0/collections/tooltipDefinitions" + "?appName=" + (appName || "") +
    "&pageType=" + (pageType || "") +
    "&sectionName=" + (sectionName || "") +
    "&glossary=" + (glossary || "") + "&v=" + _apiHash_,
  ajaxType: "GET",
  onSuccess: responseGetTooltipDefinitions,
  onFailure: responseGetTooltipDefinitionsFail,
});

const requestSetGeneratingCollectionCollapsed = (collectionId) => ({
  type: Types.REQUEST_SET_GENERATING_COLLECTION_COLLAPSED_API,
  endPoint: "commsec-node-api/1.0/collections/" + collectionId + "/generatingCollectionCollapsed",
  ajaxType: "PUT",
  onSuccess: responseSetGeneratingCollectionCollapsed,
  onFailure: responseSetGeneratingCollectionCollapsedFail,
});

const requestSetGeneratingCollectionExpanded = (collectionId) => ({
  type: Types.REQUEST_SET_GENERATING_COLLECTION_EXPANDED_API,
  endPoint: "commsec-node-api/1.0/collections/" + collectionId + "/generatingCollectionExpanded",
  ajaxType: "PUT",
  onSuccess: responseSetGeneratingCollectionExpanded,
  onFailure: responseSetGeneratingCollectionExpandedFail,
});

const requestGetCollectionHighlights = (collectionId) => ({
  type: Types.REQUEST_GET_COLLECTION_HIGHLIGHTS_API,
  endPoint: "commsec-node-api/1.0/collections/" + collectionId + "/highlights?v=" + _apiHash_,
  ajaxType: "GET",
  onSuccess: responseGetCollectionHighlights,
  onFailure: responseGetCollectionHighlightsFail,
});

export {
  onGenericApiFailure,
  onGenericApiSuccess,
  setAccessToken,
  setUserHash,
  resetFetchingFlag,
  requestGetAllCollections,
  requestGetAllCompaniesTableData,
  requestGetCollectionDetails,
  requestSetTableCollapsed,
  requestSetTableExpanded,
  resetTableLoading,
  resetSummaryModalStates,
  resetCollectionDetailsLoading,
  requestGetFavourites,
  requestSetPreferences,
  requestGetPreferences,
  requestGetImage,
  requestGetEquitySummary,
  requestGetQuote,
  requestSetGeneratingCollectionCollapsed,
  requestSetGeneratingCollectionExpanded,
  requestGetToolTipDefinitions,
  requestGetCollectionHighlights,
};
