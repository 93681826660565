import React, { Suspense, lazy, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import configureStore from './store/configureStore'
import './styles/App.scss'
import Cookies from 'js-cookie'
import { getQueryStringParameterValue } from './utils/helper'
import {
  fetchToken,
  isTokenValid,
  redirectOnTokenError
} from './utils/authApiService'
import Error from './components/Error'

const Home = lazy(() => import('./components/Home'))
const Detail = lazy(() => import('./components/Detail'))
const spinner = (
  <div className='lds-ring'>
    <div />
    <div />
    <div />
    <div />
  </div>
)

function App (props) {
  const [showLoadingSpinner, setshowLoadingSpinner] = useState(
    !(Cookies.get('ModToken') || Cookies.get('MODJSONWebToken')) &&
      !getQueryStringParameterValue('access_token') &&
      window.location.host.indexOf('commsec.com.au') > -1
  )

  useEffect(() => {
    if (
      window.location.host.indexOf('commsec.com.au') > -1 &&
      !getQueryStringParameterValue('access_token')
    ) {
      let valid = isTokenValid()
      let renewCookie = valid !== undefined && !valid
      if (
        !(Cookies.get('ModToken') || Cookies.get('MODJSONWebToken')) ||
        renewCookie
      ) {
        fetchToken(renewCookie)
          .then(() => {
            setshowLoadingSpinner(
              !(Cookies.get('ModToken') || Cookies.get('MODJSONWebToken')) &&
                !getQueryStringParameterValue('access_token') &&
                window.location.host.indexOf('commsec.com.au') > -1
            )
          })
          .catch((err) => {
            redirectOnTokenError(err)
          })
      }
    }
    localStorage.removeItem('imageRequestsArray')
  }, [])

  let store = configureStore()
  return showLoadingSpinner ? (
    spinner
  ) : (
    <Provider store={store}>
      <Router>
        <Suspense fallback={<div />}>
          <Switch>
            <Route exact path={['/', '/investmentthemes', '/collections']} component={Home} />
            <Route exact path={['/investmentthemes/detail', '/collections/detail']} component={Detail} />
            <Route path='*' component={Error} />
          </Switch>
        </Suspense>
      </Router>
    </Provider>
  )
}

export default App
