/* eslint-disable */
import Moment from "moment-timezone";

const COMMON_STATE = {
  message: "",
  AllCollections: {},
  CollectionsLoading: true,
  AllCompaniesTableData: [],
  TableDataLoading: true,
  CollectionDetails: {},
  CollectionDetailsLoading: true,
  isError: false,
  TimeStamp: Moment(new Date().getTime())
    .tz("Australia/Sydney")
    .format("hh:mm:ss A z, DD MMM YYYY"),
  FavouriteCollections: {},
  FavouriteCollectionsLoading: true,
  ImageData: null,
  ImageDataLoading: true,
  EquitySummary: {},
  EquitySummaryLoading: true,
  Quote: {},
  QuoteLoading: true,
  Preferences: [],
  PreferencesLoading: true,
  ToolTipDefinitions: {},
  ToolTipDefinitionsLoading: true,
  HighlightsLoading: true,
  UserHashReceived: false,
  UserHash: '',
  CollectionMinimumThreshold: 5
};
export default COMMON_STATE;
